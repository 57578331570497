import React from "react";

import dynamic from "next/dynamic";

import { Column, Grid, Row } from "@/components/grid";
import { useMounted } from "@/hooks/loading";
import type { Iframe as IframeSlotProps } from "@/types/contentful-api";

const Iframe = dynamic(() => import("./generic").then(mod => mod.Iframe));
const SpotifyIframe = dynamic(() => import("./spotify").then(mod => mod.SpotifyIframe));
const TypeformIframe = dynamic(() => import("./typeform").then(mod => mod.TypeformIframe));

export const IframeSlot = (props: IframeSlotProps) => {
	const mounted = useMounted();

	const url = props.iframeUrl;
	const isSpotify = url.includes("https://open.spotify.com/");
	const isTypeform = url.includes("https://evernest.typeform.com/");
	const defaultIframe = !isSpotify && !isTypeform;

	return (
		<Grid>
			<Row>
				<Column>
					{mounted && (
						<>
							{isSpotify && <SpotifyIframe {...props} />}
							{isTypeform && <TypeformIframe {...props} />}
							{defaultIframe && <Iframe {...props} />}
						</>
					)}
				</Column>
			</Row>
		</Grid>
	);
};
